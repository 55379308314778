import React, { useEffect, useState } from 'react'
import { TimelineMax, Power2 } from 'gsap';
import styles from './Specjalization.module.scss';

import Texts from '../components/Texts/Texts';
import cadImg from '../assets/images/bg-cad.jpg'


const useOnScreen = (options) => {
  const [ref, setRefSpec] = useState(null);
  const [visibleSpec, setVisible] = useState(false);

  useEffect(() => {
    const observer = new IntersectionObserver(([entry]) => {
      setVisible(entry.isIntersecting)
    }, options);
    if (ref) {
      observer.observe(ref);
    }
    return () => {
      if(ref) {
        observer.unobserve(ref)
      }
    }
  }, [ref, options])
  return [setRefSpec, visibleSpec]
}

const Specjalization = () => {

  const [setRefSpec, visibleSpec] = useOnScreen({threshold: 0.6})
  
  const [tlSpecjalization] = useState(new TimelineMax());
  let blueprint = null;
  let divText = null;
  let textAboutP = null;

    useEffect(() => {
      if(visibleSpec) {
         tlSpecjalization
          .to(blueprint, 0.9, {scaleX: 1, ease: Power2.easeOut})
          .to(divText, 0.9, {scaleX: 1, ease: Power2.easeOut}, '-=0.5')
          .to(textAboutP, 0.7, {y: 0, opacity: 1})
      }
      // eslint-disable-next-line
    }, [visibleSpec]);

  return (
    <div className={styles.wrapper} ref={setRefSpec}>

      <div className={styles.image}>
        <img className={styles.imageCad} src={cadImg} ref={img => blueprint = img} alt="blueprint"/>
      </div>

      <div className={styles.text} ref={div => divText = div}>
        <Texts reftext={p => textAboutP = p}>
          Specjalizujemy się w montażu instalacji wentylacyjnych o wysokich wymaganiach w zakresie ciśnień i szczelności, dotyczy to szeregu inwestycji dla przemysłu farmaceutycznego i elektronicznego ze strefami "czystymi". Montowane przez nas przewody wentylacyjne podlegają testom szczelności, które wykonujemy we własnym zakresie, w oparciu o specjalistyczny, certyfikowany sprzęt pomiarowy. Naszym atutem jest załoga o wieloletnim doświadczeniu zdobytym na budowach zarówno w Polsce oraz za granicą. Oferujemy również realizację dostaw sieci przewodów i komponentów wentylacyjnych.
        </Texts>
      </div>
      
    </div>
  )
}

export default Specjalization;