import React, { useState, useEffect } from 'react';
import { TimelineMax, Power1 } from 'gsap';
import styles from './Footer.module.scss';

import howto from '../assets/images/logo-howto.png';


const useOnScreen = (options) => {
  const [ref, setRefFooter] = useState(null);
  const [visibleFooter, setVisible] = useState(false);

  useEffect(() => {
    const observer = new IntersectionObserver(([entry]) => {
      setVisible(entry.isIntersecting)
    }, options);
    if (ref) {
      observer.observe(ref);
    }
    return () => {
      if(ref) {
        observer.unobserve(ref)
      }
    }
  }, [ref, options])
  return [setRefFooter, visibleFooter]
}


const Footer = () => {

  const [setRefFooter, visibleFooter] = useOnScreen({threshold: 0.6})
  
  const [tlSpecjalization] = useState(new TimelineMax());
  let showLine1 = null;
  let showLine2 = null;


    useEffect(() => {
      if(visibleFooter) {
         tlSpecjalization
          .to(showLine1, 0.7, {y: 0, ease: Power1.easeOut})
          .to(showLine2, 0.7, {x: 0, ease: Power1.easeOut}, '-=0.5')
      }
      // eslint-disable-next-line
    }, [visibleFooter]);

  return (
    <div className={styles.wrapper} ref={setRefFooter}>

      <div className={styles.line} ref={div => showLine1 = div}></div>
      <div className={styles.line2} ref={div => showLine2 = div}></div>

      <div className={styles.box}>

        <div className={styles.boxContact}>
          <h3 className={styles.title}>Zachęcamy do nawiązania współpracy</h3>
          <a className={styles.email} href="mailto:solwent@solwent.info.pl">solwent@solwent.info.pl</a>
        </div>

        <div className={styles.boxAddress}>
          <div className={styles.boxAddressLeft}>
            <p className={styles.addressText}>86-050 Solec Kujawski</p>
            <p className={styles.addressText}>Targowa 4</p>
            <p className={styles.addressText}>(52) 569 31 18</p>
            {/* <p className={styles.addressText}>601 368 848</p> */}
          </div>
          <div className={styles.boxAddressRight}>
            <img className={styles.howto} src={howto} alt="firma godna zaufania"/>
          </div>
        </div>

        <div className={styles.boxCopyright}>
          <p className={styles.copyright}><i className="far fa-copyright"/> Solwent 2019</p>
          <a className={styles.blusoft} href="https://blusoft.pl" target="_blank" rel="noopener noreferrer"><p><i style={{marginRight: '.5rem'}} className="fas fa-code"/> BluSoft.pl</p></a>
        </div>

      </div>

    </div>
  )
}

export default Footer;