import React, { useEffect, useState } from 'react';
import { TimelineMax, Power2 } from 'gsap';
import styles from './New.module.scss';

import airHome from '../assets/images/air-condition-house.jpg';
import Texts from '../components/Texts/Texts';


const useOnScreen = (options) => {
  const [ref, setRefNew] = useState(null);
  const [visibleNew, setVisible] = useState(false);

  useEffect(() => {
    const observer = new IntersectionObserver(([entry]) => {
      setVisible(entry.isIntersecting)
    }, options);
    if (ref) {
      observer.observe(ref);
    }
    return () => {
      if(ref) {
        observer.unobserve(ref)
      }
    }
  }, [ref, options])
  return [setRefNew, visibleNew]
}


const New = () => {

  const [setRefNew, visibleNew] = useOnScreen({threshold: 0.6})
  
  const [tlAbout] = useState(new TimelineMax());
  let showGray = null;
  let textAboutP = null;
  let showImg = null;

    useEffect(() => {
      if(visibleNew) {
         tlAbout
          .to(showGray, 0.8, {scaleX: 1, ease: Power2.easeOut})
          .to(textAboutP, 0.7, {y: 0, opacity: 1}, 0.3)
          .to(showImg, 0.8, {scaleY: 1, ease: Power2.easeOut}, '-=0.2')
      }
      // eslint-disable-next-line
    }, [visibleNew]);

  return (
    <div className={styles.wrapper} ref={setRefNew}>

      <div className={styles.grayDiv} ref={div => showGray = div}>
        
        <div className={styles.text}>
          <Texts reftext={p => textAboutP = p}>
            Od roku 2019 rozszerzyliśmy działania w realizacji również małych obiektów typu domy i biura służąc usługi i rozwiązania w instalacji rekuperacji i klimatyzacji z dostawą urządzeń oraz serwisem. Wiedza praktyczna i znajomość sztuki wentylacyjnej oraz efektywna organizacja pracy pozwalają na realizację dużych i małych inwestycji w krótkim terminie. 
          </Texts>
        </div>

        <div className={styles.image}>
          <img className={styles.imageHome} src={airHome} ref={img => showImg = img}  alt="klimatyzacja"/>
        </div>

      </div>
        
    </div>
  )
}

export default New;