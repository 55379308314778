import React, { useEffect, useState } from 'react';
import { TimelineMax, Power2 } from 'gsap';
import styles from './Leak.module.scss';

import Texts from '../components/Texts/Texts';
import leakImg from '../assets/images/leak-test.jpg';


const useOnScreen = (options) => {
  const [ref, setRefLeak] = useState(null);
  const [visibleLeak, setVisible] = useState(false);

  useEffect(() => {
    const observer = new IntersectionObserver(([entry]) => {
      setVisible(entry.isIntersecting)
    }, options);
    if (ref) {
      observer.observe(ref);
    }
    return () => {
      if(ref) {
        observer.unobserve(ref)
      }
    }
  }, [ref, options])
  return [setRefLeak, visibleLeak]
}


const Leak = () => {

  const [setRefLeak, visibleLeak] = useOnScreen({threshold: 0.6})
  
  const [tlSpecjalization] = useState(new TimelineMax());
  let divText = null;
  let leakImage = null;
  let textLeakP = null;

    useEffect(() => {
      if(visibleLeak) {
         tlSpecjalization
          .to(divText, 0.9, {scaleY: 1, ease: Power2.easeOut})
          .to(leakImage, 0.9, {scaleY: 1, ease: Power2.easeOut}, '-=0.5')
          .to(textLeakP, 0.7, {y: 0, opacity: 1})
      }
      // eslint-disable-next-line
    }, [visibleLeak]);

  return (
    <div className={styles.wrapper} ref={setRefLeak}>
      
      <div className={styles.text} ref={div => divText = div}>
        <Texts reftext={p => textLeakP = p}>
          Oferujemy także usługi pomiaru szczelności instalacji wentylacyjnych. Posiadane przez nas urządzenie testowe umożliwia wykonanie pomiarów zgodnie z PN-B-76001. Możemy badać instalacje niskociśnieniowe od 1000Pa oraz wysokociśnieniowe do 3000Pa.
        </Texts>
      </div>

      <div className={styles.image}>
        <img className={styles.imageLeak} src={leakImg} ref={img => leakImage = img} alt="test szczelności"/>
      </div>

    </div>
  )
}

export default Leak;