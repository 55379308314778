import React, { useState, useEffect } from 'react'
import { TimelineMax, Power1 } from 'gsap';
import styles from './Header.module.scss';

import logo from '../assets/images/solwent-logoW.png'
import arrow from '../assets/images/arrow.png';


const Header = () => {

  const [tlHeader] = useState(new TimelineMax());
  const [tlHeader2] = useState(new TimelineMax({repeat:-1}));
  let background = null;
  let showLogo = null;
  let title1 = null;
  let title2 = null;
  let title3 = null;
  let showLine1 = null;
  let showLine2 = null;
  let showImg = null;
  let showArrow = null;


  useEffect(() => {
    tlHeader
      .from(background, 0.8, {y: '-100%', ease: Power1.easeOut,}, .5)
      .from(showLogo, 0.5, {opacity: 0, y: '-30px', ease: Power1.easeOut,})
      .from(title1, 0.6, {opacity: 0, y: '30px', scale: 0})
      .from(title2, 0.6, {opacity: 0, y: '30px', scale: 0}, '-=0.4')
      .from(title3, 0.6, {opacity: 0, y: '30px', scale: 0}, '-=0.4')
      .from(showArrow, 0.6, {opacity: 0}, '-=0.2')
      .from(showImg, 0.6, { scaleY: 0, ease: Power1.easeOut,}, '-=0.2')
      .from(showLine1, 0.7, {y: '-100%', ease: Power1.easeOut}, '-=0.3')
      .from(showLine2, 0.7, {x: '-100%', ease: Power1.easeOut}, '-=0.5')

    tlHeader2
      .to(showArrow, 2, {scale: 1.1, rotation: 4, ease: Power1.easeOut,})
      .to(showArrow, 2, {scale: 1, rotation: 0, ease: Power1.easeOut,})
      // eslint-disable-next-line
  }, []);

  return (
    <div className={styles.wrapper} ref={div => background = div}>

      <div className={styles.line} ref={div => showLine1 = div}></div>
      <div className={styles.line2} ref={div => showLine2 = div}></div>

      <div className={styles.logo}>
        <img className={styles.logoImg} src={logo} alt="Solwent" ref={img => showLogo = img}/>
      </div>

      <div className={styles.leftDiv}>
        <h1 className={styles.headerTitle} ref={h1 => title1 = h1}>Witamy na</h1>
        <h1 className={styles.headerTitle} ref={h1 => title2 = h1}>Stronie</h1>
        <h1 className={styles.headerTitle} ref={h1 => title3 = h1}>Solwent  <a href="#section1"><img className={styles.arrow} src={arrow} alt="#" ref={img => showArrow = img}/></a></h1>
      </div>
      <p></p>
      <div className={styles.rightDiv} ref={div => showImg = div}>
      </div>

    </div>
  )
}

export default Header;