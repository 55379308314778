import React, { useEffect, useState }  from 'react'
import { TimelineMax, Power2 } from 'gsap';
import ScrollableAnchor, { configureAnchors } from 'react-scrollable-anchor'
import styles from './About.module.scss';

import company2 from '../assets/images/company-02.jpg';
import company3 from '../assets/images/company-03.jpg';

import Texts from '../components/Texts/Texts';

const useOnScreen = (options) => {
  const [ref, setRefAbout] = useState(null);
  const [visibleAbout, setVisible] = useState(false);

  useEffect(() => {
    configureAnchors({offset: -140, scrollDuration: 1000})

    const observer = new IntersectionObserver(([entry]) => {
      setVisible(entry.isIntersecting)
    }, options);
    if (ref) {
      observer.observe(ref);
    }
    return () => {
      if(ref) {
        observer.unobserve(ref)
      }
    }
  }, [ref, options])
  return [setRefAbout, visibleAbout]
}

const About = () => {

  const [setRefAbout, visibleAbout] = useOnScreen({threshold: 0.6})
  
  const [tlAbout] = useState(new TimelineMax());
  let textAboutP = null;
  let img1 = null;
  let img2 = null;

    useEffect(() => {
      if(visibleAbout) {
         tlAbout
          .to(textAboutP, 0.7, {y: 0, opacity: 1})
          .to(img2, 1, {x: 0, opacity: 1, ease: Power2.easeOut})
          .to(img1, 1, {x: 0, opacity: 1, ease: Power2.easeOut}, '-=0.6')
          .to(img2, .5, {scale: 1, ease: Power2.easeOut}, '-=0.6')
          .to(img1, .5, {scale: 1, ease: Power2.easeOut}, '-=0.3')
      }
      // eslint-disable-next-line
    }, [visibleAbout]);

  return (
    <>
      <div className={styles.wrapper} ref={setRefAbout}>
        <ScrollableAnchor id={"section1"}>
          <div className={styles.text}>
            <Texts reftext={p => textAboutP = p}>
              Firma działa od 2002 roku w zakresie realizacji kompletnych systemów wentylacyjno-klimatyzacyjnych. Od początku swojego istnienia firma wykonała w tej dziedzinie szereg obiektów w całej Polsce. Naszymi klientami są znane i renomowane firmy inżynierskie branży instalacyjnej oraz firmy świadczące usługi "Facility Management".
            </Texts>
          </div>
        </ScrollableAnchor>
      </div>

      <div className={styles.imgs}>
        <div className={styles.leftImg}>
          <img src={company2} alt="Solwent" className={styles.company1} ref={img => img1 = img}/>
        </div>
        <div className={styles.rightImg}>
          <img src={company3} alt="Solwent" className={styles.company3} ref={img => img2 = img}/>
        </div>
      </div>
    </>
  )
}

export default About;