import React from 'react'
import styles from './Texts.module.scss';

const Texts = ({children, reftext}) => {
  return (
    <p className={styles.text} ref={reftext}>
      {children}
    </p>
  )
}

export default Texts;