import React from 'react';
import './App.css';

import Header from './Header/Header';
import About from './About/About';
import Specjalization from './Specjalization/Specjalization';
import New from './New/New';
import Footer from './Footer/Footer';
import Leak from './Leak/Leak';
import FatArrow from './components/FatArrow/FatArrow';
import Regeneration from './Regeneration/Regeneration';

const App = () => {
  return (
    <div className="wrapper">
      <Header />
      <About />
      <Specjalization />
      <FatArrow />
      <Leak />
      <New />
      <Regeneration />
      <Footer />
    </div>
  );
}

export default App;
