import React, { useEffect, useState }  from 'react';
import { TimelineMax, Power2 } from 'gsap';
import styles from './Regeneration.module.scss';

import rekuperacja from '../assets/images/rekuperacja.jpg';
import Texts from '../components/Texts/Texts';

const useOnScreen = (options) => {
  const [ref, setRefReg] = useState(null);
  const [visibleReg, setVisible] = useState(false);

  useEffect(() => {
    const observer = new IntersectionObserver(([entry]) => {
      setVisible(entry.isIntersecting)
    }, options);
    if (ref) {
      observer.observe(ref);
    }
    return () => {
      if(ref) {
        observer.unobserve(ref)
      }
    }
  }, [ref, options])
  return [setRefReg, visibleReg]
}

const Regeneration = () => {

  const [setRefReg, visibleReg] = useOnScreen({threshold: 0.6})
  
  const [tlRegeneration] = useState(new TimelineMax());
  let textReg = null;
  let showImg = null;

    useEffect(() => {
      if(visibleReg) {
         tlRegeneration
          .to(showImg, 0.8, {scaleY: 1, ease: Power2.easeOut})
          .to(textReg, 0.7, {y: 0, opacity: 1}, '-=0.5')
      }
      // eslint-disable-next-line
    }, [visibleReg]);


  return (
    <div className={styles.wrapper} ref={setRefReg}>

      <div className={styles.rekuperacja}>
        <img src={rekuperacja} ref={img => showImg = img} alt="rekuperacja" className={styles.rekuperacjaImg}/>
      </div>
      <div className={styles.text}>
        <Texts reftext={p => textReg = p}>Podstawową korzyścią z wentylacji mechanicznej z odzyskiem ciepła, czyli z rekuperacji jest odczuwalny i rzeczywisty komfort klimatyczny. Gwarantuje go stała wymiana zużytego powietrza na świeże w pomieszczeniach. Wymiana ta – obok oszczędności na ogrzewaniu – jest istotą rekuperacji.</Texts>
      </div>
    </div>
  )
}

export default Regeneration;
