import React, { useEffect, useState } from 'react';
import { TimelineMax, Power1 } from 'gsap';

import styles from './FatArrow.module.scss';
import fatArrow from '../../assets/images/fat-arrow.png';

function FatArrow() {

  const [tlArrow] = useState(new TimelineMax({repeat:-1}));
  let imgArrow = null;

  useEffect(() => {
    tlArrow
      .to(imgArrow, 2, {y: '10px', scale: 1.1, rotation: -7, ease: Power1.easeOut, opacity: 1})
      .to(imgArrow, 2, {y: 0, scale: 1, rotation: 0, ease: Power1.easeOut, opacity: .5})

      // eslint-disable-next-line
  }, []);

  return (
    <div className={styles.wrapper}>
      <img className={styles.fatArrow} src={fatArrow} ref={img => imgArrow = img} alt="#"/>
    </div>
  )
}

export default FatArrow
